export const processors = [
    {
        id: 4,
        username: "ermd_support@sec.ph"
    },
    {
        id: 24,
        username: "mlmliwanag@sec.gov.ph"
    },
    {
        id: 25,
        username: "licudine@gmail.com"
    },
    {
        id: 26,
        username: "ostwendy"
    },
    {
        id: 39,
        username: "Rvin"
    },
    {
        id: 40,
        username: "Denz"
    },
    {
        id: 210944,
        username: "arsyagtarap@gmail.com"
    },
    {
        id: 269320,
        username: "mtestrella@sec.gov.ph"
    },
    {
        id: 30,
        username: "yanzkiepiramide@gmail.com"
    },
    {
        id: 23,
        username: "mtdmabuyo@sec.gov.ph"
    },
    //DEV
    {
        id: 972,
        username: "ermd_support"
    }
]
export const reclassify = [
    {
        id: 4,
        username: "ermd_support@sec.ph"
    },
    {
        id: 24,
        username: "mlmliwanag@sec.gov.ph"
    },
    {
        id: 14954,
        username: "nbara1@sec.gov.ph"
    },
    {
        id: 25,
        username: "licudine@gmail.com"
    },
    //DEV
    {
        id: 972,
        username: "ermd_support"
    }
]